'use client';

import Login from '@app/Components/Login';
import { useRouter } from 'next/navigation';
import { useStore } from '@app/store';

const Component = () => {
	const router = useRouter();
	const login = useStore((state) => state.login);

	return (
		<Login login={login} redirect={() => router.push('/access-finance')} />
	);
};

export default Component;
